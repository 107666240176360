import React from 'react';
import {QuoteWrapper, QuoteText, QuoteAuthor} from './Quote.styles';

interface QuoteProps {
  text: string;
  author: string;
}

export const Quote: React.FunctionComponent<QuoteProps> = (props) => {
  return (
    <QuoteWrapper>
      <QuoteText>"{props.text}"</QuoteText>
      <QuoteAuthor>- {props.author}</QuoteAuthor>
    </QuoteWrapper>
  );
};
