import React from 'react';
import Colours from '../constants/colours';

interface SoundCloudPlayerProps {
  playlist: string;
  colour?: string;
  title?: string;
}

const SoundCloudPlayer = (props: SoundCloudPlayerProps) => {
  const playlistUrl = encodeURIComponent(props.playlist);
  const colour = encodeURIComponent(props.colour || Colours.green);
  const embedUrl = `https://w.soundcloud.com/player/?url=${playlistUrl}&color=${colour}&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`;
  return (
    <iframe
      title={props.title}
      src={embedUrl}
      width="100%"
      height="450"
      scrolling="no"
      frameBorder="no"
      allow="autoplay"
      data-test-id="soundcloud-player"
    />
  );
};

export default SoundCloudPlayer;
