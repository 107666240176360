import React, {ReactElement} from 'react';
import Img from 'gatsby-image';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import {BLOCKS, Hyperlink, INLINES, MARKS} from '@contentful/rich-text-types';
import Vimeo from '@u-wave/react-vimeo';
import styled from 'styled-components';
import SoundCloudPlayer from '../components/SoundCloudPlayer';
import Typography from '../constants/typography';
import {Quote} from '../components/Quote';
import {MediaPlayer} from '@focusritegroup/web-ui';

const EMBEDDABLE_TYPES = {
  SOUNDCLOUD_PLAYER: 'ContentfulAmpifyWebSoundcloud',
  VIMEO: 'ContentfulAmpifyWebVimeo',
  QUOTE: 'ContentfulAmpifyWebQuote',
  MEDIA_PLAYER: 'ContentfulEmbeddableUrl',
};

const CTA = (attributes: {to: string; children: ReactElement[]}) => {
  const {to, children} = attributes;
  return (
    <a
      className="link-button"
      href={to}
      target="blank"
      rel="noreferrer noopener"
    >
      {children}
    </a>
  );
};

const Code = styled.code`
  font-size: ${Typography.code.fontSize};
`;

const english = <T,>(prop: {['en-GB']?: T}): T => {
  if (prop) {
    return prop['en-GB'];
  }
};

export const getRenderOptions = ({isPreview = false}) => {
  return {
    renderMark: {
      [MARKS.CODE]: (text: string) => {
        return <Code>{text}</Code>;
      },
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        if (isPreview) {
          const {title, file} = node.data.target.fields;
          return (
            <img width="100%" src={file.url} title={title} className="image" />
          );
        }
        const {title, fluid} = node.data.target;

        return (
          <Img
            className="image" // Sometimes gatsby-image in prod fails to add the needed attributes to the div container.
            imgStyle={{marginTop: '1rem'}}
            fluid={fluid}
            alt={title}
          />
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
        const type = isPreview
          ? node.data.target.sys.contentType.sys.id
          : node.data?.target?.__typename;
        const fields = isPreview
          ? node?.data?.target.fields
          : node?.data?.target;
        switch (type) {
          case isPreview ? 'webSoundcloud' : EMBEDDABLE_TYPES.SOUNDCLOUD_PLAYER:
            const playlist = fields?.playlistUrl;
            return <SoundCloudPlayer playlist={playlist} />;
          case isPreview ? 'webVideo' : EMBEDDABLE_TYPES.VIMEO:
            const url = fields?.url;
            return <Vimeo video={url} autoplay={false} responsive={true} />;
          case isPreview ? 'webQuote' : EMBEDDABLE_TYPES.QUOTE:
            const text = fields?.quote;
            const author = fields?.author;
            return <Quote text={text} author={author} />;
          case isPreview ? 'embeddableUrl' : EMBEDDABLE_TYPES.MEDIA_PLAYER:
            const embedUrl = fields?.embeddableUrl;
            const title = fields?.title;
            return <MediaPlayer url={embedUrl} title={title} />;
          default:
            throw new Error(
              `An unexpected embed type (${type}) has been found`
            );
        }
      },
      [INLINES.HYPERLINK]: (node: Hyperlink, children: ReactElement[]) => {
        const to = node.data.uri;
        return <CTA to={to} children={children} />;
      },
    },
  };
};

export default (
  richText: RenderRichTextData<ContentfulRichTextGatsbyReference>
) => {
  return renderRichText(richText, getRenderOptions({isPreview: false}));
};
