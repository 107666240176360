import styled from 'styled-components';

export const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 80%;
  justify-content: center;
  @media screen and (min-width: 840px) {
    > * {
      margin-right: 20px;
    }
  }
`;

export const QuoteText = styled.span`
  text-align: center;
  font-style: italic;
  font-size: 2rem;
`;

export const QuoteAuthor = styled.span`
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
`;
